import React from "react";
import SEO from "../components/Seo";
import Layout from "../components/layout";

const Success = () => (
  <Layout>
    <SEO
      title="Thank you"
      description="Thank you for contacting Obsidian Los Angeles, 3321 West Sunset Boulevard"
      keywords={[
        `tattoos in los angeles`,
        `los angeles tattoo artists`,
        `obsidian la tattoo`,
        `tattoos los angeles`,
        `la tattoo studios`
      ]}
    />

    <div id="main" className="alt">
      <section id="one">
        <div className="inner">
          <header className="major">
            <h1>Thank you for contacting us</h1>
          </header>
          <span className="image main"></span>
          <h4>
            Someone from Obsidian Los Angeles tattoo will follow up shortly.{" "}
          </h4>
        </div>
      </section>
    </div>
  </Layout>
);

export default Success;
